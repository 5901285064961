import clsx from 'clsx';

type InputLabelProps = {
  name?: string;
  children: React.ReactNode;
  labelClass?: string;
};

const InputLabel = ({ name, children, labelClass = '' }: InputLabelProps) => (
  <label
    className={clsx(
      'font-normal font-adieu leading-none tracking-[0.15em] uppercase text-[12px] xs:text-sm 3xl:text-sm 4xl:text-[15px] block',
      labelClass
    )}
    htmlFor={name}
  >
    {children}
  </label>
);

export default InputLabel;
