import clsx from 'clsx';
import Link from 'next/link';
import UserLoginForm from '../components/user/UserLoginForm';

const Login = () => {
  //redirct in query parameter will cause a redirect after successful login
  return (
    <>
      <div
        className={clsx(
          'grid grid-cols-1 xs:grid-cols-2 xs:min-h-screen xs:items-center w-full xs:w-screen',
          'xs:mx-[-64px] px-[20px] xs:px-[0px]' // Cheap fix to make full width work. If we end up needing this more often then we should make a better solution.
        )}
      >
        <div className="xs:grid xs:justify-center py-[100px] mb-[100px] xs:mb-0">
          <div className="text-white xs:my-[100px] mb-[45px] uppercase font-medium font-ace-bold leading-[1.1] text-[16px] xs:text-[40px] 3xl:text-[48px] 4xl:text-[56px] text-center">
            Welcome Back
          </div>
          <UserLoginForm />
          <p className="text-white text-center text-[12px] xs:text-[16px]">
            Forgot your password?{' '}
            <Link
              href="/forgot-password"
              className="font-nhg font-normal uppercase leading-[1] tracking-[0.15em] relative inline-block text-[12px] xs:text-sm 3xl:text-[15px] 4xl:text-[16px] text-orange-400 ml-[16px] mt-[15px] xs:mt-[32px]"
            >
              RESET
            </Link>
          </p>

          <p className="text-white text-center text-[12px] xs:text-[16px]">
            Don&apos;t have an account?{' '}
            <a
              href="https://deathtothestockphoto.com/"
              className="font-nhg font-normal uppercase leading-[1] tracking-[0.15em] relative inline-block text-[12px] xs:text-sm 3xl:text-[15px] 4xl:text-[16px] text-orange-400 ml-[16px] mt-[15px] xs:mt-[32px]"
            >
              Sign Up
            </a>
          </p>
        </div>
        <div
          className="hidden xs:flex bg-cover bg-center bg-no-repeat h-full"
          style={{
            backgroundImage: 'url("/assets/images/login-cover.jpeg")',
          }}
        ></div>
      </div>
    </>
  );
};

export default Login;
