import React, { InputHTMLAttributes, useState } from 'react';
import clsx from 'clsx';
import InputLabel from '../atoms/InputLabel';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  label?: string;
  hint?: string;
  labelGap?: number;
  errorAlignLeft?: boolean;
  inputFont?: string;
  letterCase?: 'normal-case' | 'uppercase' | 'lowercase' | 'capitalize';
  labelClass?: string;
  inputClass?: string;
  errorClass?: string;
  showTogglePassword?: boolean;
  backgroundColor?: string;
  textColor?: string;
};

const Input = ({
  error,
  label,
  hint,
  letterCase = 'uppercase',
  inputClass = '',
  labelClass = '',
  errorClass = '',
  labelGap,
  errorAlignLeft,
  inputFont,
  showTogglePassword = false,
  backgroundColor = 'bg-black',
  textColor = 'text-white',
  ...rest
}: InputProps) => {
  const [internalType, setInternalType] = useState(showTogglePassword ? 'password' : undefined);

  const hanldeShowPasswordClick = () => {
    setInternalType(internalType === 'text' ? 'password' : 'text');
  };

  return (
    <div className={`w-full grid gap-[${labelGap ?? 20}px]`}>
      {label && (
        <InputLabel name={rest.name} labelClass={labelClass}>
          {label}
        </InputLabel>
      )}

      <div className="flex flex-col gap-[8px] relative">
        {showTogglePassword && (
          <div
            className={clsx(
              'absolute right-[18px] cursor-pointer',
              internalType === 'text' ? 'top-[18px]' : 'top-[15px]'
            )}
            onClick={hanldeShowPasswordClick}
          >
            {internalType === 'text' ? (
              <svg
                width="20"
                height="12"
                viewBox="0 0 20 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.7858 3.62496C17.1377 1.86251 13.823 0 10 0C6.17722 0 2.86226 1.86246 1.21419 3.62496C0.408904 4.48834 0 5.35164 0 6C0 6.64836 0.408904 7.5117 1.21419 8.37504C2.86229 10.1375 6.17722 12 10 12C13.8228 12 17.1377 10.1375 18.7858 8.37504C19.5911 7.51166 20 6.64836 20 6C20 5.35164 19.5911 4.4883 18.7858 3.62496ZM10 9C11.6569 9 13 7.65685 13 6C13 4.34315 11.6569 3 10 3C8.34315 3 7 4.34315 7 6C7 7.65685 8.34315 9 10 9Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.64645 14.8536C2.45118 14.6583 2.45118 14.3417 2.64645 14.1464L4.55471 12.2382C3.14936 11.567 1.99481 10.7098 1.21419 9.87504C0.408904 9.0117 0 8.14836 0 7.5C0 6.85164 0.408904 5.98834 1.21419 5.12496C2.86226 3.36246 6.17722 1.5 10 1.5C11.6029 1.5 13.1165 1.82742 14.4553 2.33761L16.6464 0.146447C16.8417 -0.0488154 17.1583 -0.0488156 17.3536 0.146447C17.5488 0.341709 17.5488 0.658291 17.3536 0.853553L15.4453 2.76179C16.8507 3.43293 18.0052 4.29017 18.7858 5.12496C19.5911 5.9883 20 6.85164 20 7.5C20 8.14836 19.5911 9.01166 18.7858 9.87504C17.1377 11.6375 13.8228 13.5 10 13.5C8.39716 13.5 6.88359 13.1726 5.54474 12.6624L3.35355 14.8536C3.15829 15.0488 2.84171 15.0488 2.64645 14.8536ZM10 12.5C8.69219 12.5 7.44854 12.2615 6.32616 11.8809L8.26173 9.94537C8.75214 10.2946 9.35208 10.5 10 10.5C11.6569 10.5 13 9.15685 13 7.5C13 6.85208 12.7946 6.25214 12.4454 5.76173L14.6914 3.51569C16.1276 4.15076 17.2988 4.99904 18.0549 5.80742C18.7843 6.5895 19 7.21818 19 7.5C19 7.7818 18.7843 8.41042 18.0549 9.19255C16.5793 10.7703 13.5223 12.5 10 12.5ZM11.016 5.77691C10.7182 5.60096 10.3709 5.5 10 5.5C8.89543 5.5 8 6.39543 8 7.5C8 7.8709 8.10097 8.21823 8.27691 8.51598L11.016 5.77691ZM11.7383 5.05463C11.2479 4.7054 10.6479 4.5 10 4.5C8.34315 4.5 7 5.84315 7 7.5C7 8.14791 7.2054 8.74786 7.55463 9.23827L5.30861 11.4843C3.87237 10.8492 2.70116 10.0009 1.94508 9.19254C1.21573 8.41048 1 7.78182 1 7.5C1 7.2182 1.21572 6.58958 1.94508 5.80745C3.4207 4.22971 6.47766 2.5 10 2.5C11.3079 2.5 12.5515 2.73845 13.6739 3.11903L11.7383 5.05463ZM8.98402 9.22309L11.7231 6.48402C11.899 6.78177 12 7.1291 12 7.5C12 8.60457 11.1046 9.5 10 9.5C9.6291 9.5 9.28177 9.39904 8.98402 9.22309Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
        )}
        <input
          className={clsx(
            'w-full border px-[18px] h-[44px] flex items-center font-normal font-adieu leading-none tracking-[0.15em] uppercase text-[14px] xs:text-sm 3xl:text-sm 4xl:text-[15px] placeholder:text-white/40 focus:border-white/40 focus:outline-none transition',
            error ? 'border-orange-400' : 'border-white',
            inputFont ? inputFont : 'font-adieu',
            inputClass ?? '',
            backgroundColor,
            textColor,
            letterCase
          )}
          {...rest}
          type={showTogglePassword ? internalType : rest.type}
        />
        {error && (
          <span
            className={clsx(
              'ml-auto text-[12px] xs:text-sm text-orange-400 mt-2.5',
              errorAlignLeft ? 'self-start' : 'self-end',
              errorClass
            )}
          >
            {error}
          </span>
        )}
      </div>
    </div>
  );
};

export default Input;
