import { useAtom } from 'jotai';
import { signIn } from 'next-auth/react';

import { trpc } from '@/utils/trpc';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { analytics } from '../../services/analytics';
import { tokenAtom } from '../../store/userStore';
import Button from '../shared/Button';
import Input from '../shared/molecules/Input';

const UserLoginForm = () => {
  const {
    handleSubmit,
    setError,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const { data: user, refetch } = trpc.users.getAuthenticatedUser.useQuery(undefined, {
    // staleTime: Infinity,
  });

  const emailWatch = watch('email');
  const passwordlWatch = watch('password');

  useEffect(() => {
    analytics.track('Viewed login');
  }, []);

  const [loading, setLoading] = useState(false);
  const [, setToken] = useAtom(tokenAtom);
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await signIn('credentials', {
        email: data.email,
        password: data.password,
        callbackUrl: '/',
        redirect: false,
      });

      if (res?.status === 200) {
        await refetch();
      } else {
        throw {
          message: res?.error,
        };
      }

      setLoading(false);
    } catch (error) {
      let message = 'Incorrect email or password. Please try again.';

      if (!emailWatch?.length) {
        message = 'Email is required.';
      } else if (!passwordlWatch.length) {
        message = 'Password is required.';
      } else if (error.message?.includes('timeout') || error.message?.includes('Network Error')) {
        message =
          'There was an unexpected issue logging you in. Please try again or contact support.';
      }

      setError('password', {
        message,
        type: 'invalid',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    analytics.track('Logged in');
  }, [user]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="grid gap-[32px] w-full text-white ">
        <div className="w-full">
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                type="email"
                error={fieldState.error?.message}
                label="Email"
                placeholder="enter your email"
                id="email"
                letterCase="normal-case"
              />
            )}
          />
        </div>
        <div className="w-full mb-[45px] xs:mb-[100px] relative">
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                error={fieldState.error?.message}
                label="Password"
                placeholder="enter your password"
                id="password"
                letterCase="normal-case"
                showTogglePassword={true}
              />
            )}
          />
        </div>
        <div className="text-center">
          <Button loading={loading} buttonType="submit">
            Log in
          </Button>
        </div>
      </form>
    </>
  );
};

export default UserLoginForm;
